/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import Typist from 'react-typist';
import styled from 'styled-components';

const StyledTypist = styled.div`
  .Typist {
    display: inline-block;
  }

  .has-highlight {
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 211, 6, 0.6) 50%
    );
    background-size: 200%;
    background-position: -100% 0;
    transition: background-position 0.8s ease-out;
    &.highlighted {
      background-position: 0 0;
    }
  }
`;

const TypingIntro = () => {
  const handleTypingDone = () => {
    setTimeout(() => {
      document.querySelector('.has-highlight').classList.add('highlighted');
    }, 200);
  };

  return (
    <StyledTypist>
      <Typist
        cursor={{ hideWhenDone: true, hideWhenDoneDelay: 200 }}
        avgTypingDelay={90}
        stdTypingDelay={30}
        onTypingDone={handleTypingDone}
        startDelay={500}
      >
        // Bruce Brophy
        <br />
        <span className="has-highlight">// Full Stack Developer</span>
      </Typist>
    </StyledTypist>
  );
};

export default TypingIntro;
