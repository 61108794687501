import styled from 'styled-components';

const StyledContactForm = styled.div`
  padding: 8rem 0;
  width: 100%;
  background: var(--black);
  h2 {
    margin-top: 0;
    font-family: var(--fontSecondary);
    font-size: 3rem;
    color: var(--yellow);
  }
  .success-message {
    margin-top: 1.5rem;
    font-family: var(--fontSecondary);
    font-size: 1.8rem;
    color: white;
  }
  form {
    display: inline-block;
    width: 100%;
  }
  .form-row {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    @media (max-width: 767.98px) {
      flex-direction: column;
    }
  }
  label {
    margin-bottom: 2rem;
    display: block;
    width: 100%;
    font-family: var(--fontSecondary);
    font-size: 1.8rem;
    letter-spacing: 1px;
    color: white;
    span {
      color: #cc0505;
    }
  }
  input,
  textarea {
    display: block;
    margin-top: 1rem;
    padding: 1.5rem 2rem;
    width: 100%;
    background: #1e1e1e;
    border: 2px solid #383838;
    font-family: var(--fontPrimary);
    color: white;
    border-radius: 1rem;
    &:focus {
      border-color: #d2ad00;
      outline: none;
    }
  }
  button {
    padding: 1.5rem 4rem;
    border-radius: 1rem;
    font-family: var(--fontSecondary);
    letter-spacing: 2px;
    text-transform: uppercase;
    background: var(--yellow);
    border: 0;
    cursor: pointer;
    transition: background 0.2s ease-out;
    &:hover {
      background: #d2ad00;
    }
  }
`;

export default StyledContactForm;
