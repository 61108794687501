import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Container from '../styles/Container';
import TypingIntro from '../components/TypingIntro';
import HomeProjects from '../components/HomeProjects';
import HomePosts from '../components/HomePosts';
import ContactForm from '../components/ContactForm';

const StyledIntro = styled.div`
  margin: 0;
  width: 100%;
  min-height: 560px;
  padding: 20rem 0 20rem;
  background: var(--black);
  font-family: var(--fontSecondary);
  font-weight: 700;
  font-size: 5rem;
  color: white;
  @media (max-width: 767.98px) {
    min-height: 422px;
    font-size: 3.5rem;
    padding: 15rem 0 15rem;
  }
  @media (max-width: 575.98px) {
    .has-highlight {
      font-size: 2.4rem;
    }
  }
`;

export const query = graphql`
  query {
    posts: allSanityPost(limit: 3) {
      nodes {
        id
        title
        slug {
          current
        }
        categories {
          id
          title
          colorHex
        }
        publishedAt
      }
    }
    projects: allSanityProject(
      limit: 3
      sort: { fields: publishedAt, order: ASC }
    ) {
      nodes {
        id
        slug {
          current
        }
        title
        mainImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        publishedAt
        categories {
          id
          title
          colorHex
        }
        body
      }
    }
  }
`;

const Index = ({ data }) => (
  <>
    <SEO title="👋🏼 Hey there!" />
    <StyledIntro>
      <Container>
        <TypingIntro />
      </Container>
    </StyledIntro>
    <Container>
      {data.projects.nodes.length > 0 && (
        <HomeProjects projects={data.projects.nodes} />
      )}
      {data.posts.nodes.length > 0 && <HomePosts posts={data.posts.nodes} />}
    </Container>
    <ContactForm />
  </>
);

export default Index;
