import styled from 'styled-components';

export const PostSection = styled.section`
  margin-top: 9rem;
  margin-bottom: 9rem;
  a {
    display: inline-block;
    margin-top: 2rem;
    font-family: var(--fontSecondary);
    font-size: 1.8rem;
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const PostGrid = styled.div`
  margin-top: 3rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
  }
`;

export const PostCard = styled.div`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;
  background: var(--grey);
  border-radius: 2rem;
  transition: box-shadow 0.4s ease-out;
  time {
    font-family: var(--fontSecondary);
    font-size: 1.4rem;
    color: var(--black);
  }
  a {
    margin-top: 1rem;
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  h3 {
    margin: 0 0 2rem;
    font-family: var(--fontSecondary);
    font-size: 2.4rem;
    color: var(--black);
  }
  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16),
      0px 4px 74px rgba(0, 0, 0, 0.1);
  }
`;
