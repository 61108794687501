import styled from 'styled-components';

export const ProjectSection = styled.section`
  margin-top: 9rem;
  margin-bottom: 9rem;
  > a {
    display: inline-block;
    margin-top: 2rem;
    font-family: var(--fontSecondary);
    font-size: 1.8rem;
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ProjectCard = styled.div`
  margin-top: 3rem;
  background: var(--grey);
  width: 100%;
  border-radius: 2rem;
`;

export const ProjectNav = styled.div`
  padding: 3rem 3rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 3.5rem;
  @media (max-width: 767.98px) {
    gap: 1rem;
  }
  button {
    padding: 1rem 2.5rem;
    border: 0;
    border-radius: 3rem;
    background: var(--greyDarker);
    font-family: var(--fontSecondary);
    color: var(--black);
    cursor: pointer;
    &.active {
      background: rgba(30, 99, 242, 0.2);
      color: var(--blue);
    }
  }
`;

export const ProjectBody = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  padding: 3rem;
  .content {
    display: flex;
  }
  .gatsby-image-wrapper {
    margin-right: 3rem;
    margin-bottom: 25px;
    width: 300px;
    border-radius: 1rem;
  }

  .body {
    margin-bottom: 3rem;
  }

  a {
    font-family: var(--fontSecondary);
    color: var(--black);
  }

  @media (max-width: 767.98px) {
    padding: 3rem;
    .content {
      flex-direction: column;
    }
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
`;
