import { Link } from 'gatsby';
import React from 'react';
import { format } from 'date-fns';
import SectionTitle from '../styles/SectionTitle';
import CategoryList from './CategoryList';
import { PostSection, PostGrid, PostCard } from '../styles/HomePostStyles';

const HomePosts = ({ posts }) => (
  <PostSection>
    <SectionTitle>Posts</SectionTitle>
    <PostGrid>
      {posts.map(post => (
        <PostCard key={post.id}>
          <div>
            <time dateTime={format(new Date(post.publishedAt), 'dd-MM-yyyy')}>
              {format(new Date(post.publishedAt), 'dd-MM-yyyy')}
            </time>
            <Link to={`/blog/${post.slug.current}`}>
              <h3>{post.title}</h3>
            </Link>
          </div>
          <CategoryList categories={post.categories} />
        </PostCard>
      ))}
    </PostGrid>
    <Link to="/blog">See all posts -></Link>
  </PostSection>
);

export default HomePosts;
