import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Container from '../styles/Container';
import StyledContactForm from '../styles/StyledContactForm';

const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = async data => {
    const res = await fetch('/api/contact', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const resData = await res.json();

    reset();

    setIsSubmitted(true);
  };

  return (
    <>
      <StyledContactForm>
        <Container>
          <h2>Let's work together!</h2>
          <form onSubmit={handleSubmit(onSubmit)} id="contact">
            <div className="form-row">
              <label htmlFor="name">
                Name
                <input
                  type="text"
                  name="name"
                  id="name"
                  ref={register({ required: true })}
                  placeholder="Marty McFly"
                />
                {errors.name && <span>The name field is required</span>}
              </label>
              <label htmlFor="name">
                Email
                <input
                  type="email"
                  name="email"
                  id="email"
                  ref={register({ required: true })}
                  placeholder="marty@mcfly.com"
                />
                {errors.email && <span>The email field is required</span>}
              </label>
            </div>
            <div className="form-row">
              <label htmlFor="body">
                Message
                <textarea
                  name="body"
                  id="body"
                  rows="5"
                  ref={register({ required: true })}
                />
                {errors.body && <span>The message field is required</span>}
              </label>
            </div>
            <button type="submit">Submit</button>
            {isSubmitted && (
              <p className="success-message">Thanks for reaching out!</p>
            )}
          </form>
        </Container>
      </StyledContactForm>
    </>
  );
};

export default ContactForm;
