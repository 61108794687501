import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import SectionTitle from '../styles/SectionTitle';
import CategoryList from './CategoryList';
import {
  ProjectSection,
  ProjectCard,
  ProjectBody,
  ProjectNav,
} from '../styles/HomeProjectStyles';

const HomeProjects = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState({});

  useEffect(() => {
    if (projects.length) {
      setSelectedProject(projects[0]);
    }
  }, []);

  return (
    <ProjectSection>
      <SectionTitle>Projects</SectionTitle>
      <ProjectCard>
        <ProjectNav>
          {projects.map(project => (
            <button
              type="button"
              key={project.id}
              className={project.id === selectedProject.id ? 'active' : ''}
              onClick={() => setSelectedProject(project)}
            >
              {project.title}
            </button>
          ))}
        </ProjectNav>

        {projects.map(project => (
          <ProjectBody
            key={project.id}
            show={project.id === selectedProject.id}
          >
            <div className="content">
              <div>
                {project.mainImage && (
                  <Link to={`/projects/${project.slug.current}`}>
                    <Img
                      fluid={project.mainImage?.asset?.fluid}
                      alt={project.title}
                    />
                  </Link>
                )}
              </div>
              <div className="body">
                <ReactMarkdown
                  source={project.body.slice(0, 400).concat('...')}
                />
                <Link to={`/projects/${project.slug.current}`}>
                  Read more ->
                </Link>
              </div>
            </div>
            <CategoryList categories={project.categories} />
          </ProjectBody>
        ))}
      </ProjectCard>
      <Link to="/projects">See all projects -></Link>
    </ProjectSection>
  );
};

export default HomeProjects;
